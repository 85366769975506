//Import components
import mapboxgl from 'mapbox-gl'
import markerPopup from './markerPopup'
import updateMarkerPosition from './updateMarkerPosition'
import { circle } from '@turf/circle'

// Intended to create popup vehicle marker or update existing
// Also updates layer marker position and popup position on map using smooth animation
export default function setMapMarkers(
  dispatch,
  locationsDataRef,
  locationIndexRef,
  mapRef,
  mapPopupVehiclesMarkersRef,
  singleMarkerData,
  closeMarkerPopupCenterTimeoutIdRef,
  setLastMapPopupVehiclesMarkersElementData,
  setLastMapVehiclesMarkersData,
  setForceRerenderByMarkerUpdate,
  vehiclesDataRef,
  vehiclesLayerDataRef,
  vehiclesMoveDetectionCircleDataRef,
  vehiclesMoveDetectionCircleLayerDataRef,
  vehiclesActivityDetectionCircleDataRef,
  vehiclesActivityDetectionCircleLayerDataRef
) {
  if (singleMarkerData) {
    const coords = singleMarkerData.geometry.coordinates
    const props = singleMarkerData.properties
    const markerId = props.vehicle_id
    const customVehicleStatus = props.custom_vehicle_status
    const isMarkerActive = props?.vehicle_map_marker_activity_data?.is_marker_active || false
    const mapMarkerBearing = props?.vehicle_map_marker_activity_data?.map_marker_bearing || 0
    let mapPopupVehicleMarker // invisible, dedicated for popup only

    // Update popup marker
    if (mapPopupVehiclesMarkersRef.current[markerId]) {
      mapPopupVehicleMarker = mapPopupVehiclesMarkersRef.current[markerId] // Get map marker

      updateMarkerPosition(mapPopupVehicleMarker, singleMarkerData, coords, markerId, mapRef, vehiclesDataRef) // Update popup marker position on the map

      let popup = mapPopupVehicleMarker.getPopup()

      // Update popup content when it is opened
      if (popup.isOpen()) {
        popup.setDOMContent(markerPopup(singleMarkerData))
      }
    } else {
      // Create html marker on the map
      const markerEl = document.createElement('div')
      markerEl.className = 'map-marker'
      markerEl.dataset.type = props.type
      markerEl.style.zIndex = 'auto'

      // Create the popup
      const popup = new mapboxgl.Popup({
        offset: [0, -7],
        anchor: 'bottom',
        closeButton: false,
        closeOnClick: true,
        focusAfterOpen: false,
        keepInView: true,
      }).setMaxWidth('307px')

      mapPopupVehiclesMarkersRef.current[markerId] = new mapboxgl.Marker(markerEl).setLngLat(coords).setPopup(popup).addTo(mapRef.current)
      mapPopupVehicleMarker = mapPopupVehiclesMarkersRef.current[markerId] // Get map marker

      // Center map by marker coordinates when popup is opened
      const onPopupOpen = () => {
        if (!Object.keys(vehiclesDataRef.current).length || !vehiclesDataRef.current[markerId]) {
          return
        }

        popup.setDOMContent(markerPopup(vehiclesDataRef.current[markerId])) // Create popup content
        const popupHeight = popup.getElement().clientHeight // Get the height of the popup
        const markerLngLat = mapPopupVehicleMarker.getLngLat() // Get the center coordinates of the mapMarker
        const markerPixel = mapRef.current.project(markerLngLat) // Convert the mapMarker coordinates to pixel coordinates
        const verticalOffset = popupHeight / 2 // Calculate the vertical offset based on the popup height

        // Calculate the final target pixel coordinates to center the popup
        const targetPixel = {
          x: markerPixel.x,
          y: markerPixel.y - verticalOffset,
        }

        const targetLngLat = mapRef.current.unproject(targetPixel) // Convert the target pixel coordinates back to geographical coordinates
        clearTimeout(closeMarkerPopupCenterTimeoutIdRef.current) // Clear timout for map center after marker popup close

        // Center the map to the target coordinates using `flyTo`
        mapRef.current.flyTo({
          center: targetLngLat,
          zoom: mapRef.current.getZoom(), // Maintain the current zoom level
          bearing: mapRef.current.getBearing(), // Maintain the current map rotation
          duration: 2000,
          essential: true,
        })
      }

      // Center map by default coordinates when popup is closed
      const onPopupClose = () => {
        // Exit if no location data
        if (!locationsDataRef?.current) {
          return
        }

        const longitude = locationsDataRef.current[Number(locationIndexRef.current)].location.longitude
        const latitude = locationsDataRef.current[Number(locationIndexRef.current)].location.latitude
        const zoom = locationsDataRef.current[Number(locationIndexRef.current)].zoom

        clearTimeout(closeMarkerPopupCenterTimeoutIdRef.current) // Clear timout for map center after marker popup close

        closeMarkerPopupCenterTimeoutIdRef.current = setTimeout(function () {
          mapRef.current.flyTo({
            center: [longitude, latitude],
            zoom: zoom,
            duration: 2000,
            essential: true,
          })
        }, 5000)
      }

      // Add event listeners to the popup
      popup.on('open', onPopupOpen)
      popup.on('close', onPopupClose)
    }

    // add custom fields to properties
    singleMarkerData.properties.mapMarkerBearing = mapMarkerBearing
    singleMarkerData.properties.isMarkerActive = isMarkerActive
    singleMarkerData.properties.isMarkerArrow = isMarkerActive

    // Ignore Markers which should be always visible
    if (
      customVehicleStatus === 'Battery < 4%' ||
      customVehicleStatus === 'In use' ||
      customVehicleStatus === 'Reserved' ||
      customVehicleStatus === 'Stolen' ||
      customVehicleStatus === 'Transportation' ||
      customVehicleStatus === 'Charging'
    ) {
      singleMarkerData.properties.isMarkerActive = true
    }

    // Ignore Markers which should be always visible
    if (customVehicleStatus === 'In use' || customVehicleStatus === 'Reserved' || customVehicleStatus === 'Transportation') {
      singleMarkerData.properties.isMarkerArrow = true
    }
  }

  if (vehiclesDataRef?.current && Object.keys(vehiclesDataRef?.current)?.length > 0) {
    vehiclesLayerDataRef.current.features = Object.values(vehiclesDataRef.current)

    // Update vehicles layer
    if (mapRef.current && vehiclesLayerDataRef.current.features?.length > 0 && mapRef.current.getSource('vehiclesSource')) {
      mapRef.current.getSource('vehiclesSource').setData(vehiclesLayerDataRef.current)
    }

    /* Uncomment for testing only */
    updateVehicleMoveDetectionCircleLayer(mapRef, vehiclesMoveDetectionCircleDataRef, vehiclesMoveDetectionCircleLayerDataRef, singleMarkerData)
    updateVehicleActivityDetectionCircleLayer(
      mapRef,
      vehiclesActivityDetectionCircleDataRef,
      vehiclesActivityDetectionCircleLayerDataRef,
      singleMarkerData
    )
    /* Uncomment for testing only */
  }

  // Intended to set last map popup Markers element data, is called every time data are updated
  setLastMapPopupVehiclesMarkersElementData(mapPopupVehiclesMarkersRef.current)

  // Set last vehicles data, is called every time data are updated
  setLastMapVehiclesMarkersData(vehiclesDataRef.current)
  setForceRerenderByMarkerUpdate(singleMarkerData)
}

// Update vehicles move detection circle layer (only for testing)
function updateVehicleMoveDetectionCircleLayer(
  mapRef,
  vehiclesMoveDetectionCircleDataRef,
  vehiclesMoveDetectionCircleLayerDataRef,
  singleMarkerData
) {
  if (singleMarkerData) {
    const props = singleMarkerData.properties
    const markerId = props.vehicle_id
    const vehicleMoveDetectionData = props?.vehicle_move_detection_data
    const circleRadius = vehicleMoveDetectionData?.circle_radius
    const longitude = vehicleMoveDetectionData?.correction_circle_center_location?.longitude
    const latitude = vehicleMoveDetectionData?.correction_circle_center_location?.latitude

    if (!longitude && !latitude) {
      return
    }

    const center = [Number(longitude), Number(latitude)]
    const radius = circleRadius / 1000 || 0.1 // Radius in kilometers (100 meters)
    const options = { steps: 64, units: 'kilometers' }
    vehiclesMoveDetectionCircleDataRef.current[markerId] = circle(center, radius, options)
    vehiclesMoveDetectionCircleDataRef.current[markerId].properties = singleMarkerData.properties
    vehiclesMoveDetectionCircleLayerDataRef.current.features = Object.values(vehiclesMoveDetectionCircleDataRef.current)

    // Update circle layer
    if (
      mapRef.current &&
      vehiclesMoveDetectionCircleLayerDataRef.current.features?.length > 0 &&
      mapRef.current.getSource('vehicleMoveDetectionCirclesSource')
    ) {
      mapRef.current.getSource('vehicleMoveDetectionCirclesSource').setData(vehiclesMoveDetectionCircleLayerDataRef.current)
    }
  }
}

// Update vehicles activity detection circle layer (only for testing)
function updateVehicleActivityDetectionCircleLayer(
  mapRef,
  vehiclesActivityDetectionCircleDataRef,
  vehiclesActivityDetectionCircleLayerDataRef,
  singleMarkerData
) {
  if (singleMarkerData) {
    const props = singleMarkerData.properties
    const markerId = props.vehicle_id
    const vehicleActivityData = props?.vehicle_map_marker_activity_data
    const circleRadius = vehicleActivityData?.circle_radius
    const vehicleMoveDetectionData = vehicleActivityData?.vehicle_move_detection_data
    const longitude = vehicleMoveDetectionData?.correction_circle_center_location?.longitude
    const latitude = vehicleMoveDetectionData?.correction_circle_center_location?.latitude

    if (!longitude && !latitude) {
      return
    }

    const center = [Number(longitude), Number(latitude)]
    const radius = circleRadius / 1000 || 0.05 // Radius in kilometers (50 meters)
    const options = { steps: 64, units: 'kilometers' }
    vehiclesActivityDetectionCircleDataRef.current[markerId] = circle(center, radius, options)
    vehiclesActivityDetectionCircleDataRef.current[markerId].properties = singleMarkerData.properties
    vehiclesActivityDetectionCircleLayerDataRef.current.features = Object.values(vehiclesActivityDetectionCircleDataRef.current)

    // Update circle layer
    if (
      mapRef.current &&
      vehiclesActivityDetectionCircleLayerDataRef.current.features?.length > 0 &&
      mapRef.current.getSource('vehicleActivityDetectionCirclesSource')
    ) {
      mapRef.current.getSource('vehicleActivityDetectionCirclesSource').setData(vehiclesActivityDetectionCircleLayerDataRef.current)
    }
  }
}
